import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DropdownModel } from '../core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DependentService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'Dependent');
  }

  getDependentByCustomerID(customerID: string) : Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.route() + `/GetOptionsByCustomerID/${customerID}`);
  }
}
